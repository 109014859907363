<template>
    <div class="Profile-performance">
        <div class="Profile-performance__select-block">
            <form-group class="mr-4">
                <label class="form-label">{{ $t('profile.profile.class-placeholder') }}</label>
                <el-select
                    class="w-100"
                    autocomplete="nope"
                    v-model="selectedClass"
                    @change="getStudents"
                    :placeholder="$t('profile.profile.class-placeholder')"
                >
                    <el-option v-for="studentClass in classes"
                        :key="studentClass.id"
                        :value="studentClass.id"
                        :label="studentClass.name">
                    </el-option>
                </el-select>
            </form-group>

            <form-group>
                <label class="form-label">{{ $t('profile.performance.student-placeholder') }}</label>
                <el-select
                    class="w-100"
                    autocomplete="nope"
                    v-model="selectedStudent"
                    @change="getData"
                    :placeholder="$t('profile.performance.student-placeholder')"
                >
                    <el-option
                        v-for="(student, id) in students"
                        :key="id"
                        :label="student"
                        :value="id">
                    </el-option>
                </el-select>
            </form-group>
        </div>
        <div class="performance-card" v-if="selectedStudent">
            <div class="performance-card__left">
                <div class="performance-card__left-header">
                    <div class="performance-card__select-block">
                        <div class="performance-card__title">
                            {{$t("profile.performance.title")}}
                        </div>
                        <div class="performance-card__period-block">
                            <span class="performance-card__per">{{$t("profile.performance.per")}}</span>
                            <el-select
                                :placeholder="$t('profile.performance.quarter-1')"
                                @change="getData"
                                v-model="period">
                                <el-option
                                    v-for="item in periods"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div v-if="period === 'week'">
                            <el-button @click="prevWeek" type="text" icon="el-icon-arrow-left">{{ $t("profile.performance.prev-week") }}</el-button>
                            <el-button @click="nextWeek" type="text">{{ $t("profile.performance.next-week") }} <i class="el-icon-arrow-right"></i></el-button>
                        </div>
                        <div v-if="period === 'month'">
                            <el-button @click="prevMonth" type="text" icon="el-icon-arrow-left">{{ $t("profile.performance.prev-month") }}</el-button>
                            <el-button @click="nextMonth" type="text">{{ $t("profile.performance.next-month") }} <i class="el-icon-arrow-right"></i></el-button>
                        </div>
                    </div>
                    <div v-if="type">
                        <div class="performance-card__score">
                            {{$t("profile.performance.average-score")}}
                        </div>
                        <div :class="'performance-card__score-amount ' + type">
                            {{ gradeAvg[type] }}
                        </div>
                    </div>
                </div>
                <div class="performance-card__graph">
                    <vue-apex-charts height="340" type="bar" :options="chartOptions" :series="series"></vue-apex-charts>
                </div>
            </div>
            <div class="performance-card__right">
                <div class="performance-card__row">
                    <div class="performance-card__type performance-card__type--class" :class="{'active': type === 'class'}" @click="setType('class')">
                        <div class="performance-card__type-title">
                            {{$t("profile.performance.class-work")}}
                        </div>
                        <div class="performance-card__type-amount">
                            {{ gradeAvg['class'] }}
                        </div>
                    </div>
                    <div class="performance-card__type performance-card__type--home" :class="{'active': type === 'home'}" @click="setType('home')">
                        <div class="performance-card__type-title">
                            {{$t("profile.performance.home-work")}}
                        </div>
                        <div class="performance-card__type-amount">
                            {{ gradeAvg['home'] }}
                        </div>
                    </div>
                </div>
                <div class="performance-card__row">
                    <div class="performance-card__type performance-card__type--control" :class="{'active': type === 'control'}" @click="setType('control')">
                        <div class="performance-card__type-title">
                            {{$t("profile.performance.control-work")}}
                        </div>
                        <div class="performance-card__type-amount">
                            {{ gradeAvg['control'] }}
                        </div>
                    </div>
                    <div class="performance-card__type performance-card__type--test" :class="{'active': type === 'test'}" @click="setType('test')">
                        <div class="performance-card__type-title">
                            {{$t("profile.performance.test")}}
                        </div>
                        <div class="performance-card__type-amount">
                            {{ gradeAvg['test'] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Profile-attendance__without-result" v-if="!selectedStudent">{{ $t('profile.performance.select-student-class') }}</div>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            currentDay: false,
            currentMonth: false,
            loading: false,
            classes: [],
            selectedClass: '',
            students: [],
            selectedStudent: '',
            gradeAvg: {
                class: '0',
                home: '0',
                control: '0',
                test: '0'
            },
            data: [],
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        borderRadius: 6
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                },
                yaxis:{
                    opposite: true,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            },
            type: '',
            period: "week",
            periods: [
                {
                    id: "week",
                    name: this.$t('profile.performance.week')
                },
                {
                    id: "month",
                    name: this.$t('profile.performance.month')
                },
                {
                    id: "quarter/1",
                    name: this.$t('profile.performance.quarter-1')
                },
                {
                    id: "quarter/2",
                    name: this.$t('profile.performance.quarter-2')
                },
                {
                    id: "quarter/3",
                    name: this.$t('profile.performance.quarter-3')
                },
                {
                    id: "quarter/4",
                    name: this.$t('profile.performance.quarter-4')
                },
            ],
        }
    },
    mounted() {
        this.getClasses()
    },
    methods: {
        getClasses() {
            this.loading = true
            this.$http.get(`${window.API_ROOT}/api/reference/teacher-classes`)
                .then((res) => {
                    this.classes = res.body.data
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
        },
        getStudents(classId) {
            this.loading = true
            this.$http.get(`${window.API_ROOT}/api/teacher/students/${classId}`)
                .then((res) => {
                    this.students = res.body
                    this.loading = false
                }).catch(() => {
                this.loading = false
            })
        },
        getData() {
            this.type = ''
            this.loading = true
            let year = false
            let month = false
            let day = false
            let period = this.period
            let url = `${window.API_ROOT}/api/journal/performance/${period}`
            if (this.currentDay) {
                year = this.currentDay.getFullYear()
                month = this.currentDay.getMonth() + 1
                day = this.currentDay.getDate()
                url += `?year=${year}&month=${month}&day=${day}`
            }
            if (this.currentMonth) {
                year = this.currentMonth.getFullYear()
                month = this.currentMonth.getMonth() + 1
                day = this.currentMonth.getDate()
                url += `?year=${year}&month=${month}&day=${day}`
            }
            this.$http.post(url, {user_id: this.selectedStudent})
                .then((res) => {
                    this.loading = false
                    this.data = res.body.grades
                    this.gradeAvg.control = res.body.control
                    this.gradeAvg = res.body.avg
                    this.chartRender()
                }).catch(() => {
                this.loading = false
            })
        },
        prevWeek() {
            this.currentMonth = false
            if (!this.currentDay) {
                this.currentDay = new Date()
            }
            this.currentDay = this.getprevweek(this.currentDay);
            this.getData()
        },
        nextWeek() {
            this.currentMonth = false
            if (!this.currentDay) {
                this.currentDay = new Date()
            }
            this.currentDay = this.getnextweek(this.currentDay);
            this.getData()
        },
        prevMonth() {
            this.currentDay = false
            if (!this.currentMonth) {
                this.currentMonth = new Date()
            }
            this.currentMonth = this.getprevmonth(this.currentMonth);
            this.getData()
        },
        nextMonth() {
            this.currentDay = false
            if (!this.currentMonth) {
                this.currentMonth = new Date()
            }
            this.currentMonth = this.getnextmonth(this.currentMonth);
            this.getData()
        },
        getnextweek(today) {
            return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
        },
        getprevweek(today) {
            return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        },
        getnextmonth(today) {
            return new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
        },
        getprevmonth(today) {
            return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        },
        setType(type) {
            this.type = type
            this.chartRender()
        },
        chartRender() {
            let controlData = [];
            let classData = [];
            let homeworkData = [];
            let testData = [];
            let labels = [];
            let colors = [];
            this.data.forEach(item => {
                if (this.type === '') {
                    classData.push(item.classwork)
                    homeworkData.push(item.homework)
                    controlData.push(item.controlwork)
                    testData.push(item.test)
                    colors = ['#473F95', '#55953F', '#D23168', '#DE9A06']
                }
                if (this.type === 'class') {
                    classData.push(item.classwork)
                    colors = ['#473F95']
                }
                if (this.type === 'home') {
                    homeworkData.push(item.homework)
                    colors = ['#55953F']
                }
                if (this.type === 'control') {
                    controlData.push(item.controlwork)
                    colors = ['#D23168']
                }
                if (this.type === 'test') {
                    testData.push(item.test)
                    colors = ['#DE9A06']
                }
                labels.push(item.label)
            })
            this.series = []
            if (classData.length) {
                this.series.push({
                    name: this.$t("profile.performance.class-work"),
                    data: classData
                })
            }
            if (homeworkData.length) {
                this.series.push({
                    name: this.$t("profile.performance.home-work"),
                    data: homeworkData
                })
            }
            if (controlData.length) {
                this.series.push({
                    name: this.$t("profile.performance.control-work"),
                    data: controlData
                })
            }
            if (testData.length) {
                this.series.push({
                    name: this.$t("profile.performance.test"),
                    data: testData
                })
            }
            this.chartOptions = {...this.chartOptions, ...{
                    colors: colors,
                    xaxis: {
                        categories: labels
                    },
                    yaxis: {
                        tickPlacement: 'between',
                        min: 0,
                        max: 100,
                    }
                }
            }
        }
    },
}
</script>
<style lang="less" scoped>

.Profile-performance__select-block {
    display: flex;
    max-width: 1122px;
    margin-bottom: 5px;
}
.Profile-performance__select-block .form-group {
    width: 260px;
}
/deep/ .Profile-performance__select-block .el-select-dropdown__item.selected {
    color: #D23168!important;
}
.form-label {
    display: none;
}
.Profile-performance__select-block .form-group .signin-label {
    display: none;
}
/deep/ .apexcharts-canvas svg{
    overflow: visible;
}
.Profile-attendance__without-result {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 459px;
    font-size: 35px;
    line-height: 41px;
    font-weight: bold;
    color: #A39FCA;
    // border: 1px solid #DFE0EB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 100px;
}
.performance-card {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 8px;
    background: #FFFFFF;
  max-width: 100%;
    display: flex;
    flex-direction: row;
}
.performance-card__left {
    width: calc(100% - 340px);
    padding: 31px 60px 17px 33px;
}
.performance-card__left-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.performance-card__title {
    color: #473F95;
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    letter-spacing: 0.4px;
    padding-bottom: 5px;
}
.performance-card__per {
    color: #6A707E;
    letter-spacing: 0.01em;
    font-size: 12px;
    line-height: 18px;
}
.el-button--text {
    color: #473f95
}
/deep/ .performance-card__period-block .el-input__inner {
    border: none;
    color: #D23168;
    padding: 0;
    height: 20px;
    line-height: 20px;
}
/deep/ .performance-card__period-block .el-select-dropdown__item.selected > span {
    color: #D23168;
}
/deep/ .performance-card__period-block .el-select-dropdown__item.selected {
    color: #D23168;
}
/deep/ .performance-card__period-block .el-input__inner::placeholder {
    color: #D23168;
    padding: 0;
}
/deep/ .performance-card__period-block .el-input__suffix {
    left: 20px;
}
/deep/ .performance-card__period-block .el-select .el-input .el-select__caret {
    color: #D23168;
    font-size: 10px;
}
/deep/ .performance-card__period-block .el-input__icon {
    line-height: 20px;
}
.performance-card__right {
    width: 340px;
}
.performance-card__type {
    border-radius: 5px;
    padding: 29px;
    text-align: center;
    cursor: pointer;
}
.performance-card__type-title {
    letter-spacing: 0.3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 6px;
}

.performance-card__type-amount {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.3px;
}
.performance-card__type--class {
    color: #473F95;
}
.performance-card__type--class.active{
    background-color: #EEEAFB;
}
.performance-card__type--home {
    color: #55953F;
}
.performance-card__type--home.active{
    background-color: #F4FBEA;
}
.performance-card__type--control {
    color: #D23168;
}
.performance-card__type--control.active{
    background-color: #FBEAEA;
}
.performance-card__type--test {
    color: #DE9A06
}
.performance-card__type--test.active{
    background-color: #FFF7CE;
}
.performance-card__score {
    color: #303030;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 4px;
}
.performance-card__score-amount {
    font-weight: bold;
    font-size: 31px;
    line-height: 36px;
    text-align: right;
}
.performance-card__score-amount.class {
    color: #473F95;
}
.performance-card__score-amount.home {
    color: #55953F;
}
.performance-card__score-amount.control {
    color: #D23168;
}
.performance-card__score-amount.test {
    color: #DE9A06;
}
.performance-card__graph {
    width: 100%;
    height: 348px;
}
@media (max-width: 1200px) {
    .performance-card__right {
        width: 200px;
    }
    .performance-card__left {
        width: calc(100% - 200px);
    }
}

@media (max-width: 991px) {
    .Profile-attendance__without-result {
        margin: 0 auto;
        letter-spacing: 0.3px;
        font-size: 24px;
        line-height: 28px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        background-image: url("/images/profile/performance-bg-mob.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        min-height: 530px;
        padding: 0 32px;
        text-align: center;
    }
    .Profile-performance__select-block {
        flex-direction: column;
    }
    .Profile-performance__select-block .form-group {
        width: 100%;
        margin-bottom: 0;
    }
    .Profile-performance__select-block .form-group .el-select {
        width: 100%;
        max-width: 288px;
    }
}
@media (max-width: 767px) {
    .performance-card {
        flex-direction: column;
        border: none;
    }
    .performance-card__left {
        width: 100%;
        padding: 0 12px 16px 12px;
    }
    .performance-card__right {
        width: 100%;
    }
    .performance-card__row {
        display: flex;
        flex-direction: row;
    }
    .performance-card__type {
        width: 50%;
        border-radius: 5px;
        padding: 12px;
    }
    .performance-card__type--class {
        background: #EEEAFB;
        color: #473F95;
    }
    .performance-card__type--home {
        background: #F4FBEA;
        color: #55953F;
    }
    .performance-card__type--control {
        background: #FBEAEA;
        color: #D23168;
    }
    .performance-card__type--test {
        background: #FFF7CE;
        color: #DE9A06;
    }
    .performance-card__type-title {
        font-size: 14px;
        line-height: 22px;
    }
    .performance-card__type-amount {
        font-size: 20px;
        line-height: 23px;
    }
    .performance-card__right .performance-card__row:first-child {
        margin-bottom: 8px;
    }
    .performance-card__row .performance-card__type:first-child {
        margin-right: 8px;
    }
}
@media (max-width: 575px) {
    .Profile-performance__select-block .form-group .el-select {
        width: 100%;
        max-width: none;
    }
}
</style>